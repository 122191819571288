<template>
  <div>
    <div class="hero-login">
      <div class="bg"></div>
      <v-row class="inner" no-gutters justify="center">
        <v-col md="6" lg="4" sm="8" cols="11" class="text-center">
          <h2>{{$vuetify.lang.t('$vuetify.login.willkommen')}}</h2>
          <h1 v-html="$vuetify.lang.t('$vuetify.login.portal')"></h1>
          <v-form ref="login-form" @submit.prevent="submit" lazy-validation>
            <h3>{{$vuetify.lang.t('$vuetify.login.anmelden')}}</h3>
            <v-text-field
                background-color="white"
                :label="$vuetify.lang.t('$vuetify.login.email')"
                type="email"
                v-model="email"
                :rules="emailValidationRules"
                outlined
                dense
                required
            ></v-text-field>
            <v-text-field
                background-color="white"
                v-model="passwort"
                :label="$vuetify.lang.t('$vuetify.login.passwort')"
                :rules="[v => !!v || $vuetify.lang.t('$vuetify.login.passwort_erforderlich')]"
                type="password"
                outlined
                dense
                required
            >
              <template v-slot:append v-if="showIdButton">
                <v-btn icon @click="loginWithPublicKey">
                  <v-icon>mdi-fingerprint</v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <p class="text-left">
              <a href="#" @click="forgotPasswordDialog = true" @click.prevent="">{{$vuetify.lang.t('$vuetify.login.passwort_vergessen')}}</a>
            </p>
            <v-btn block depressed color="primary" type="submit" :loading="submitting">
              {{$vuetify.lang.t('$vuetify.login.anmelden')}}
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </div>
    <v-row no-gutters justify="center">
      <v-col md="6" lg="4" sm="8" cols="11">
        <p class="mt-10">{{$vuetify.lang.t('$vuetify.login.portal_beschreibung')}}</p>
        <p class="legal-links mt-7">
          <a @click="faq = true" href="#">{{$vuetify.lang.t('$vuetify.navigation.faq')}}</a> | <a @click="openImpressum" target="_blank">{{$vuetify.lang.t('$vuetify.legal.impressum')}}</a> | <a :href="datenschutzUrl" target="_blank">{{$vuetify.lang.t('$vuetify.legal.datenschutz')}}</a>
        </p>
      </v-col>
    </v-row>
    <v-dialog
        v-model="forgotPasswordDialog"
        persistent
        max-width="290"
    >
      <v-form ref="forgot-password" @submit.prevent="submitForgotPassword" lazy-validation>
        <v-card>
          <v-card-title>
            {{$vuetify.lang.t('$vuetify.login.passwort_vergessen_dialog')}}
          </v-card-title>
          <v-card-text>
            <p>
              {{$vuetify.lang.t('$vuetify.login.passwort_vergessen_dialog_hinweis')}}
            </p>
            <v-text-field
                :label="$vuetify.lang.t('$vuetify.login.email')"
                type="email"
                v-model="forgotPasswordEmail"
                :rules="emailValidationRules"
                outlined
                dense
                required
            ></v-text-field>
            <div class="text-right">
              <v-btn depressed block @click="forgotPasswordDialog = false" class="mb-4">
                {{$vuetify.lang.t('$vuetify.abbrechen')}}
              </v-btn>
              <v-btn
                  color="primary"
                  depressed
                  block
                  type="submit"
                  :loading="submittingForgotPassword"
                  :disabled="submittingForgotPassword"
              >
                {{$vuetify.lang.t('$vuetify.login.passwort_vergessen_anfordern_button')}}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="saveCredentialsDialog" max-width="500" persistent>
      <v-card>
        <v-card-title>
          {{ $vuetify.lang.t('$vuetify.anmeldedaten.public_key') }}
        </v-card-title>
        <v-card-text>
          <p>{{ $vuetify.lang.t('$vuetify.login.public_key_hinweis') }}</p>
          <div class="text-right">
            <v-btn depressed to="/dashboard" class="mr-4">{{ $vuetify.lang.t('$vuetify.nein') }}</v-btn>
            <v-btn color="primary" depressed @click="saveCredentials">{{ $vuetify.lang.t('$vuetify.ja') }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="faq" max-width="800" persistent>
      <v-card>
        <v-card-title>
          {{$vuetify.lang.t('$vuetify.navigation.faq')}}
          <v-spacer/>
          <v-btn icon @click="faq = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <h3 class="mb-4">{{$vuetify.lang.t('$vuetify.faq.ueberschrift')}}</h3>
          <FAQ/>
          <div class="text-right">
            <v-btn depressed block @click="faq = false" class="my-4" color="primary">
              {{$vuetify.lang.t('$vuetify.schliessen')}}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ImpressumDialog v-model="impressumDialog"/>
  </div>
</template>

<style lang="sass">
.hero-login
  padding: 42px 0 52px
  position: relative
  .inner
    position: relative
  .bg
    background: url(/assets/images/bg-3840.webp)
    background-size: cover
    position: absolute
    right: 0
    left: 0
    top: 0
    bottom: 0
    &::after
      content: ""
      position: absolute
      left: 0
      right: 0
      top: 0
      bottom: 0
      background-color: rgba(52,59,59,0.2)
  .v-input__append-inner
    margin-top: 2px !important
  h2
    color: var(--v-primary-base)
    font-weight: 300
    font-size: 20px
    position: relative
  h1
    font-size: 40px
    font-weight: 200
    margin-bottom: 20px
    position: relative
  h3
    font-size: 20px
    font-weight: 300
    margin-bottom: 12px
    position: relative
  h1, h3
    color: #fff
  a
    font-size: 14px
    color: #343B3B
    &:hover
      text-decoration: none
.legal-links
  a
    text-decoration: none
    padding: 0 5px
    &:hover
      text-decoration: underline
    &:first-child
      padding-left: 0
</style>

<script>
import client from '@/utils/client';
import EventBus from '@/utils/event-bus';
import Webauthn from '@/utils/webauthn';
import FAQ from '@/components/FAQ';
import ImpressumDialog from '@/components/ImpressumDialog';

export default {
  components: {FAQ,ImpressumDialog},
  data: () => ({
    submitting: false,
    email: '',
    passwort: '',
    emailValidationRules: [],
    forgotPasswordDialog: false,
    forgotPasswordEmail: '',
    submittingForgotPassword: false,
    saveCredentialsDialog: false,
    showIdButton: false,
    datenschutzUrl: '/assets/documents/Datenschutzhinweise.pdf',
    agbUrl: '/assets/documents/agb.pdf',
    impressumUrl: false,
    faq: false,
    impressumDialog: false,
  }),
  mounted() {
    this.emailValidationRules = [
      v => !!v || this.$vuetify.lang.t('$vuetify.login.email_erforderlich'),
      v => /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(v) || this.$vuetify.lang.t('$vuetify.login.email_ungueltig')
    ];
    EventBus.$on('changeVermieter', vermieter => {
      if(vermieter.datenschutz) {
        this.datenschutzUrl = process.env.VUE_APP_API_URL + '/var/uploads/' + vermieter.datenschutz;
      } else {
        this.datenschutzUrl = '/assets/documents/Datenschutzhinweise.pdf';
      }
      if(vermieter.agb) {
        this.agbUrl = process.env.VUE_APP_API_URL + '/var/uploads/' + vermieter.agb;
      } else {
        this.agbUrl = '/assets/documents/agb.pdf';
      }
      if(vermieter.impressum) {
        this.impressumUrl = process.env.VUE_APP_API_URL + '/var/uploads/' + vermieter.impressum;
      } else {
        this.impressumUrl = false;
      }
    });
    this.loginWithPublicKey();
  },
  methods: {
    submit() {
      if(this.$refs['login-form'].validate()) {
        this.submitting = true;
        client.post('login/mieter', {
          email: this.email,
          passwort: this.passwort,
        }).then(response => {
          localStorage.setItem('token', response.data.token);
          this.$store.commit('user/load', response.data.mieter);
          this.$store.commit('vertrag/load', response.data.mieter.vertraege.length ? response.data.mieter.vertraege[0] : response.data.mieter.mitvertraege[0]);
          this.submitting = false;
          if(process.env.VUE_APP_WEBAUTHN === '1' && localStorage.getItem('credentialId') === null) {
            this.saveCredentialsDialog = true;
            localStorage.setItem('credentialId', '0');
          } else {
            this.$router.push('/dashboard');
          }
        }).catch(() => {
          this.submitting = false;
        });
      }
    },
    submitForgotPassword() {
      if(this.$refs['forgot-password'].validate()) {
        this.submittingForgotPassword = true;
        let url = window.location.href.replace(window.location.pathname, '/optin/{token}');
        client.post('/login/mieter/passwort-vergessen', {
          email: this.forgotPasswordEmail,
          url: url,
        }).then(response => {
          this.submittingForgotPassword = false;
          if(response.data.success) {
            this.forgotPasswordDialog = false;
            this.$store.commit('snackbar/set', {show: true, color: 'success', message: this.$vuetify.lang.t('$vuetify.login.passwort_vergessen_erfolg')});
          } else {
            this.$store.commit('snackbar/set', {show: true, color: 'error', message: this.$vuetify.lang.t('$vuetify.login.passwort_vergessen_fehler')});
          }
        }).catch(() => {
          this.submittingForgotPassword = false;
        });
      }
    },
    saveCredentials() {
      Webauthn.createPublicKey(this.$store.state.user).then(data => {
        let payload = {
          clientDataJSON: data.response.clientDataJSON  ? Webauthn.arrayBufferToBase64(data.response.clientDataJSON) : null,
          attestationObject: data.response.attestationObject ? Webauthn.arrayBufferToBase64(data.response.attestationObject) : null,
          challenge: Webauthn.arrayBufferToBase64(Webauthn.challenge),
        };
        client.post('/mieter/credential', payload).then(() => {
          localStorage.setItem('credentialId', Webauthn.arrayBufferToBase64(data.rawId));
          this.$store.commit('user/load', {credential: true});
          this.$store.commit('snackbar/set', {show: true, color: 'success', message: this.$vuetify.lang.t('$vuetify.login.id_gespeichert')});
          this.$router.push('/dashboard');
        }).catch(() => {
          this.$router.push('/dashboard');
        });
      }).catch(() => {
        this.$store.commit('snackbar/set', {show: true, color: 'error', message: this.$vuetify.lang.t('$vuetify.unbekannter_fehler')});
        this.$router.push('/dashboard');
      });
    },
    loginWithPublicKey() {
      if(process.env.VUE_APP_WEBAUTHN === '1' &&localStorage.getItem('credentialId') && localStorage.getItem('credentialId').length > 1) {
        Webauthn.getPublicKey(localStorage.getItem('credentialId')).then(data => {
          let payload = {
            id: data.rawId ? Webauthn.arrayBufferToBase64(data.rawId) : null,
            clientDataJSON: data.response.clientDataJSON  ? Webauthn.arrayBufferToBase64(data.response.clientDataJSON) : null,
            authenticatorData: data.response.authenticatorData ? Webauthn.arrayBufferToBase64(data.response.authenticatorData) : null,
            signature : data.response.signature ? Webauthn.arrayBufferToBase64(data.response.signature) : null,
            challenge: Webauthn.arrayBufferToBase64(Webauthn.challenge),
          };
          client.post('/login/mieter/credential', payload).then(response => {
            localStorage.setItem('token', response.data.token);
            this.$store.commit('user/load', response.data.mieter);
            this.$store.commit('vertrag/load', response.data.mieter.vertraege.length ? response.data.mieter.vertraege[0] : response.data.mieter.mitvertraege[0]);
            this.$router.push('/dashboard');
          }).catch(() => {
            localStorage.removeItem('credentialId');
          });
        }).catch(() => {
          this.showIdButton = true;
        });
      }
    },
    openImpressum() {
      if(this.impressumUrl) {
        window.open(this.impressumUrl);
      } else {
        this.impressumDialog = true;
      }
    }
  }
}
</script>
